import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

import { Card, Props as CardProps } from './Card'

export interface Props {
  list: CardProps[]
  subtitle?: string
  title?: string
}

export const Cards = memo(function Cards({ list, subtitle, title }: Props) {
  if (list.length < 1) {
    return null
  }

  return (
    <Container>
      {title ? (
        <FadeInUp>
          <Title>{title}</Title>
        </FadeInUp>
      ) : null}
      {subtitle ? (
        <FadeInUp>
          <Subtitle>{subtitle}</Subtitle>
        </FadeInUp>
      ) : null}

      <Wrapper row wrap>
        {list.map((item, index) => (
          <Card key={index} {...item} />
        ))}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: 12.5rem 0;
  padding: 0 12.083vw;
  text-align: center;

  @media (max-width: 1199px) {
    padding: 0 5.556vw;
  }

  @media (max-width: 1023px) {
    margin: 7.5rem 0;
    padding: 0 1.875rem;
  }

  @media (max-width: 1023px) {
    margin: 6.25rem 0;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  font-weight: 300;
  letter-spacing: 0.1062rem;
  line-height: 1.25rem;
  margin-bottom: 1.875rem;

  @media (max-width: 1023px) {
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }
`

const Subtitle = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryLight3};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.125rem;
  line-height: 3.6875rem;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 2.5rem;
    line-height: 2.9375rem;
  }
`

const Wrapper = styled(FlexBox)`
  margin: 1.25rem -5.556vw 0 0;
  &:before {
    content: '';
    width: 100vw;
    background: ${({ theme }) => theme.colors.variants.neutralLight3};
    position: absolute;
    top: 32.2vh;
    bottom: 39.2vh;
    left: -12.083vw;
    z-index: -1;
  }

  @media (max-width: 1199px) {
    &:before {
      left: -5.556vw;
    }
  }

  @media (max-width: 1023px) {
    margin: 0 -1.875rem 0 0;

    &:before {
      display: none;
    }
  }

  @media (max-width: 767px) {
    margin-right: 0;
  }
`
