import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  description?: string
  image?: ImageProps
  title?: string
}

export const Card = memo(function Card({ description, image, title }: Props) {
  if (!description || !title) {
    return null
  }

  return (
    <Container>
      <Background>
        {image ? (
          <LazyLoadComponent>
            <Image {...image} />
          </LazyLoadComponent>
        ) : null}
      </Background>
      {title ? <Title>{title}</Title> : null}
      {description ? (
        <Description dangerouslySetInnerHTML={{ __html: description }} />
      ) : null}
    </Container>
  )
})

const Container = styled.div`
  width: calc(50% - 5.556vw);
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  margin: 6.25rem 5.556vw 0 0;
  text-align: left;

  @media (max-width: 1023px) {
    width: calc(50% - 1.875rem);
    margin: 3.75rem 1.875rem 0 0;
  }

  @media (max-width: 767px) {
    width: 100%;
    margin-right: 0;
  }
`

const Background = styled.div`
  background: ${({ theme }) => theme.colors.variants.secondaryDark3};
  margin-bottom: 2.5rem;
  padding-bottom: 75%;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
`

const Title = styled.h3`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.25rem;
  line-height: 1.4375rem;
  text-transform: uppercase;
`

const Description = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  font-weight: 300;
  line-height: 2rem;
  margin-top: 1.25rem;
  padding-left: 1.375rem;
  position: relative;
  &:before {
    content: '';
    width: 0.125rem;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralLight2};
    position: absolute;
    top: 0;
    left: 0;
  }
`
